import { ReservationType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { ReservationData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Reservation';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import reservation from '~/params/reservation.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useProduits from '~/utils/useProduits';

const PageEspaceGestionReservations: FC<
  PageProps & EspaceProps & UserProps
> = ({ espace, location, user, params: { espaceId } }) => {
  const { t } = useTranslation();
  const items = useProduits({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List<ReservationType, ReservationData>
          itemList={Item}
          model={
            new ReservationData({
              espaceId,
              params: reservation,
            })
          }
          removeAddButton
          search={location.search}
          subMenu={items}
          title={t('produits.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceGestionReservations);
